import React from 'react'

const TwitterIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 5.80003C21.2483 6.12612 20.4534 6.34169 19.64 6.44003C20.4982 5.92735 21.1413 5.12081 21.45 4.17003C20.6436 4.65009 19.7608 4.98832 18.84 5.17003C18.2245 4.5026 17.405 4.05832 16.5098 3.90688C15.6147 3.75543 14.6945 3.90538 13.8938 4.33322C13.093 4.76105 12.4569 5.44256 12.0852 6.27086C11.7135 7.09917 11.6273 8.02742 11.84 8.91003C10.2094 8.82755 8.61444 8.40298 7.15865 7.66389C5.70287 6.9248 4.41885 5.88772 3.39 4.62003C3.02914 5.25019 2.83952 5.96385 2.84 6.69003C2.83872 7.36441 3.00422 8.02864 3.32176 8.62359C3.63929 9.21854 4.09902 9.72574 4.66 10.1C4.00798 10.0823 3.36989 9.90733 2.8 9.59003V9.64003C2.80489 10.5849 3.13599 11.4991 3.73731 12.228C4.33864 12.9569 5.17326 13.4557 6.1 13.64C5.74326 13.7486 5.37288 13.8058 5 13.81C4.74189 13.807 4.48442 13.7836 4.23 13.74C4.49391 14.5528 5.00462 15.2632 5.69107 15.7722C6.37753 16.2812 7.20558 16.5636 8.06 16.58C6.6172 17.7153 4.83588 18.3349 3 18.34C2.66574 18.3411 2.33174 18.3211 2 18.28C3.87443 19.4903 6.05881 20.1327 8.29 20.13C9.82969 20.146 11.3571 19.855 12.7831 19.2741C14.2091 18.6932 15.505 17.8339 16.5952 16.7465C17.6854 15.6591 18.548 14.3654 19.1326 12.9409C19.7172 11.5164 20.012 9.98975 20 8.45003C20 8.28003 20 8.10003 20 7.92003C20.7847 7.33484 21.4615 6.61745 22 5.80003V5.80003Z"
      fill="#6F7974"
    />
  </svg>
)

export default TwitterIcon
