import React from 'react'

const CNBVLogo = () => (
  <svg
    width="42"
    height="32"
    viewBox="0 0 42 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="CNBV">
      <path
        id="Fill 1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.2236 2.18134H9.77393L11.1336 0.129517H26.7781L28.2236 2.18134Z"
        fill="#6F7974"
      />
      <path
        id="Fill 2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0519 0L9.52051 2.31099H28.4796L26.8514 0H11.0519ZM11.2078 0.259046H26.6973L27.9602 2.05183H10.0199L11.2078 0.259046Z"
        fill="#6F7974"
      />
      <path
        id="Fill 3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.5967 29.8109H14.147L15.5067 31.8627H31.1511L32.5967 29.8109Z"
        fill="#6F7974"
      />
      <path
        id="Fill 4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8965 29.6814L15.428 31.9924H31.2274L32.8556 29.6814H13.8965ZM14.3959 29.9411H32.3361L31.0732 31.7339H15.5837L14.3959 29.9411Z"
        fill="#6F7974"
      />
      <path
        id="Fill 5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.896 31.863L4.35038 17.2994L3.15039 19.4343L11.2445 31.7836L13.896 31.863Z"
        fill="#6F7974"
      />
      <path
        id="Fill 6"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.98975 19.4387L11.1641 31.9104L14.1503 31.9999L4.34118 17.0342L2.98975 19.4387ZM11.3256 31.6561L3.31142 19.4291L4.36012 17.5642L13.6421 31.7254L11.3256 31.6561Z"
        fill="#6F7974"
      />
      <path
        id="Fill 7"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.26651 18.1707L11.611 3.4967L9.00442 3.53895L1.08057 15.9818L2.26651 18.1707Z"
        fill="#6F7974"
      />
      <path
        id="Fill 8"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.92328 3.41023L0.959531 15.9158L0.920898 15.9765L2.2566 18.4418L11.8592 3.36267L8.92328 3.41023ZM1.24193 15.986L9.08739 3.66634L11.3646 3.62941L2.27826 17.8985L1.24193 15.986Z"
        fill="#6F7974"
      />
      <path
        id="Fill 9"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.91 14.7788L28.4155 0.186707H31.0226L39.1822 12.7209L37.91 14.7788Z"
        fill="#6F7974"
      />
      <path
        id="Fill 10"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.1685 0.0579834L37.9142 15.0364L39.3459 12.7202L31.1441 0.120831L31.1033 0.0579834H28.1685ZM28.6648 0.31649H30.9441L39.0205 12.7228L37.908 14.5225L28.6648 0.31649Z"
        fill="#6F7974"
      />
      <path
        id="Fill 11"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.751 28.5267L40.0034 13.8434L41.2645 16.0101L33.3188 28.5267H30.751Z"
        fill="#6F7974"
      />
      <path
        id="Fill 12"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.5049 28.6558H33.3975L41.4237 16.0123L40.0065 13.5773L30.5049 28.6558ZM39.9965 14.1088L41.1014 16.0072L33.2363 28.3969H30.9932L39.9965 14.1088Z"
        fill="#6F7974"
      />
      <g id="Group 21">
        <path
          id="Fill 13"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.62589 16.3335C8.6641 17.523 9.56712 19.1771 11.3828 19.1357C12.0647 19.1203 12.8898 18.959 13.825 18.3085C13.6685 18.6896 13.4374 19.3502 13.1536 20.0655C12.4189 20.4264 11.4993 20.4807 10.6327 20.4807C8.34001 20.4807 6.48145 18.4087 6.48145 15.8526C6.48145 13.2965 8.47558 11.6361 10.7682 11.6361C11.6845 11.6496 12.3173 11.71 12.7424 11.8013C12.7507 12.5418 12.731 12.8977 12.735 13.3207C11.8769 12.8226 11.1631 12.7676 10.6027 12.8053C9.01966 12.9116 8.54793 14.6706 8.62589 16.3335Z"
          fill="#6F7974"
        />
        <path
          id="Fill 15"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.947 20.4002C20.947 20.4002 19.1655 20.9255 16.4333 15.9751C15.8212 14.7753 15.4388 15.1385 15.4388 15.1385C15.4388 15.1385 15.4652 18.3948 15.4716 19.3629C15.4716 19.7058 15.952 20.1692 15.952 20.1692H14.069C14.069 20.1692 14.3022 19.7662 14.3022 19.4234C14.3022 19.1659 14.3606 15.0878 14.3022 12.9809C14.2578 12.3014 13.8247 11.8416 13.8247 11.8416H16.062L19.554 16.8947C19.554 16.8947 19.9307 17.0432 19.8828 16.5964C19.8525 16.3142 19.9987 12.4457 19.4837 11.8517H21.3919C21.3919 11.8517 20.9803 12.1109 20.9821 12.7048C20.9839 13.2988 20.947 20.4002 20.947 20.4002Z"
          fill="#6F7974"
        />
        <path
          id="Fill 17"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.2858 18.9599C24.8274 19.2204 24.0342 19.0847 24.0342 19.0847V17.5951V16.1829C24.0342 16.1829 24.7037 16.0519 25.1082 16.2457C25.6099 16.4858 25.9285 17.0995 25.9285 17.5951C25.9285 18.043 25.744 18.6995 25.2858 18.9599ZM24.0342 13.8857V12.9436C24.0342 12.9436 24.6624 12.8412 24.9575 13.0495C25.3786 13.3467 25.3309 13.5876 25.3309 13.9917C25.3309 14.3567 25.143 14.7859 24.7695 14.9984C24.396 15.2105 24.0342 15.1 24.0342 15.1V13.8857ZM27.9407 17.5479C27.8705 15.9523 26.1885 15.4626 26.1885 15.4626C26.1885 15.4626 27.3833 14.854 27.4842 13.8429C27.5849 12.8315 26.5184 11.8416 25.6831 11.8416H21.5981C21.5981 11.8416 21.92 12.0136 21.92 12.3067V19.6333C21.92 19.8895 21.5981 20.1057 21.5981 20.1057H25.8686C26.7115 20.1057 28.011 19.1439 27.9407 17.5479Z"
          fill="#6F7974"
        />
        <path
          id="Fill 19"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.0411 20.4163C32.0411 20.4163 30.2395 20.017 29.9135 19.1199C29.5875 18.2227 27.269 11.8416 27.269 11.8416H29.6551L31.3651 17.7496C31.3651 17.7496 31.6633 18.4466 32.0411 17.3643C32.3053 16.6077 33.1388 14.0436 33.2738 12.6691C33.3318 12.0777 32.7967 11.8416 32.7967 11.8416H34.7653L32.0411 20.4163Z"
          fill="#6F7974"
        />
      </g>
    </g>
  </svg>
)

export default CNBVLogo
