import React from 'react'
import { bool } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { track } from 'utils/tracking'
import { formatQueryParams } from 'utils/formatQueryParams'
import { institutionLogos, socialIcons } from './footer.utils'
import Desktop from './Menu/Desktop'
import Mobile from './Menu/Mobile'

import {
  DesktopContainer,
  IconContainer,
  IconsContainer,
  InstitutionsContainer,
  LegalContainer,
  LegalItems,
  LegalLinks,
  LegalSubcontainer,
  Link,
  MobileContainer,
  RightsContainer,
  RightsText,
  SocialMediaContainer,
  Text,
  TextContainer,
  Wrapper,
} from './footer.style'

const propTypes = {
  hideBarsMobile: bool,
}
// eslint-disable-next-line max-lines-per-function
const Footer = ({ hideBarsMobile }) => {
  const { t } = useTranslation()
  const translateOption = { returnObjects: true }
  const legalText = t('footer:legalText.phrases', translateOption)
  const footerLinks = t('footer:navigation.footer.links', translateOption)
  const footerRights = t('footer:navigation.footer.rights', translateOption)
  const footerCreditas = t('footer:navigation.footer.creditas', translateOption)
  const institutionLinks = t('footer:institutionLinks', translateOption)
  const socialLinks = t('footer:socialLinks', translateOption)

  const keyGenerator = () =>
    `_${Math.random()
      .toString(36)
      .substr(2, 9)}`

  const trackLegal = type => {
    const trackActionTypes = {
      privacyNotice: 'GoToPrivacyNoticePage',
      termsConditions: 'GoToTermsConditionsPage',
    }
    track({
      event: 'gaMainMX',
      category: 'landingpage',
      action: trackActionTypes[type],
    })
  }

  const trackSocialMedia = type => {
    const trackActionTypes = {
      Facebook: 'OpenFbContact',
      Twitter: 'OpenTwContact',
      Instagram: 'OpenInContact',
    }
    track({
      event: 'gaMainMX',
      category: 'landingpage',
      action: trackActionTypes[type],
    })
  }

  if (hideBarsMobile) return <Wrapper />

  return (
    <Wrapper>
      <DesktopContainer>
        <Desktop />
      </DesktopContainer>
      <MobileContainer>
        <Mobile />
      </MobileContainer>
      <LegalContainer>
        <IconsContainer>
          <InstitutionsContainer>
            {institutionLinks.map((institution, index) => (
              <IconContainer
                key={index}
                href={formatQueryParams(institution.link)}
                target="_blank"
                rel="noreferrer"
                aria-label={institution.label}
              >
                {institutionLogos[index]()}
              </IconContainer>
            ))}
          </InstitutionsContainer>
          <SocialMediaContainer>
            {socialLinks.map((social, index) => (
              <IconContainer
                key={index}
                target="_blank"
                rel="noreferrer"
                href={formatQueryParams(social.link)}
                aria-label={social.label}
                onClick={() => {
                  trackSocialMedia(social.label)
                }}
              >
                {socialIcons[index]()}
              </IconContainer>
            ))}
          </SocialMediaContainer>
        </IconsContainer>
        <TextContainer>
          {legalText.map(phrase => (
            <Text
              key={keyGenerator()}
              color="neutral.40"
              component="p"
              variant="bodyXsRegular"
              dangerouslySetInnerHTML={{ __html: phrase }}
            />
          ))}
        </TextContainer>
        <LegalSubcontainer>
          <LegalItems>
            {footerLinks.map(link => (
              <LegalLinks key={keyGenerator()} variant="bodySmRegular">
                <Link
                  key={link.href}
                  href={formatQueryParams(link.href)}
                  onClick={() => {
                    trackLegal(link.type)
                  }}
                >
                  {link.text}
                </Link>
              </LegalLinks>
            ))}
          </LegalItems>
          <RightsContainer>
            <RightsText
              color="neutral.40"
              component="p"
              variant="bodySmRegular"
            >
              {footerCreditas}
            </RightsText>
            <RightsText
              color="neutral.40"
              component="p"
              variant="bodySmRegular"
            >
              {new Date().getFullYear()}
            </RightsText>
            <RightsText
              color="neutral.40"
              component="p"
              variant="bodySmRegular"
            >
              {footerRights}
            </RightsText>
          </RightsContainer>
        </LegalSubcontainer>
      </LegalContainer>
    </Wrapper>
  )
}

Footer.propTypes = propTypes

export default Footer
