import {
  Accordion,
  AccordionItem,
  selectTheme,
  styled,
  media,
} from '@creditas-ui/react'

const Wrapper = styled.section`
  background-color: ${selectTheme('colors.neutral.10')};
  padding-bottom: 20px;
`

const AccordionContainer = styled(Accordion)`
  padding-left: 20px;
  width: 95%;

  > div > div > div {
    margin-right: 0;
  }
`

const Item = styled(AccordionItem)`
  border-bottom: none;

  ${media.down('5xl')} {
    padding-bottom: 0;
  }
`

const MenuLink = styled.a`
  text-decoration: none;
  color: ${selectTheme('colors.neutral.90')};
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 20px;
`

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50px 20px 24px;
  ${media.down('2xl')} {
    margin: 32px 20px 24px;
  }
`

const AddressContainer = styled.div`
  padding-top: 24px;
  ${media.down('2xl')} {
    padding-top: 16px;
    max-width: 240px;
  }
`

export {
  Wrapper,
  AccordionContainer,
  Item,
  MenuLink,
  ContactContainer,
  AddressContainer,
}
