import React from 'react'

// eslint-disable-next-line max-lines-per-function
const BuroLogo = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="4" fill="#6F7974" />
    <rect
      x="1.68408"
      y="1.6842"
      width="28.6316"
      height="28.6316"
      rx="4"
      fill="#1F2D27"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6784 20.309C12.6327 20.309 12.5882 20.3048 12.5414 20.3048C12.425 20.3048 12.1375 20.3227 12.1375 20.5216C12.1375 20.6416 12.2573 20.7058 12.3554 20.7058C12.5665 20.7058 12.6784 20.5774 12.6784 20.409V20.309ZM12.0416 19.7967C12.1489 19.7346 12.2767 19.6904 12.425 19.6904C12.7582 19.6904 12.8928 19.8494 12.8928 20.1577V20.6261C12.8928 20.7556 12.8974 20.8145 12.9019 20.8482H12.7023V20.7019H12.6977C12.6475 20.7703 12.5573 20.8755 12.3611 20.8755C12.1101 20.8755 11.9731 20.7692 11.9731 20.5219C11.9731 20.2388 12.2709 20.1493 12.4912 20.1493C12.5756 20.1493 12.595 20.1493 12.6783 20.1546C12.6783 19.9588 12.6064 19.8588 12.3908 19.8588C12.2709 19.8588 12.1397 19.903 12.053 19.9746L12.0416 19.7967Z"
      fill="#6F7974"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.6997 20.3115C20.6541 20.3115 20.6085 20.3073 20.5628 20.3073C20.4453 20.3073 20.1578 20.3252 20.1578 20.5252C20.1578 20.6441 20.2776 20.7083 20.3757 20.7083C20.5868 20.7083 20.6997 20.5799 20.6997 20.4115V20.3115ZM20.0631 19.7992C20.1703 19.7371 20.297 19.6929 20.4453 19.6929C20.7785 19.6929 20.9131 19.8519 20.9131 20.1613V20.6297C20.9131 20.7581 20.9177 20.8181 20.9223 20.8507H20.7226V20.7044H20.718C20.6678 20.7728 20.5777 20.878 20.3814 20.878C20.1304 20.878 19.9946 20.7717 19.9946 20.5255C19.9946 20.2413 20.2913 20.1518 20.5115 20.1518C20.5959 20.1518 20.6153 20.1518 20.6998 20.1571C20.6998 19.9613 20.6267 19.8613 20.4122 19.8613C20.2913 19.8613 20.1612 19.9055 20.0745 19.9771L20.0631 19.7992Z"
      fill="#6F7974"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.6922 20.3115C26.6466 20.3115 26.6021 20.3073 26.5565 20.3073C26.4389 20.3073 26.1514 20.3252 26.1514 20.5252C26.1514 20.6441 26.2712 20.7083 26.3693 20.7083C26.5804 20.7083 26.6922 20.5799 26.6922 20.4115V20.3115ZM26.0546 19.7992C26.1619 19.7371 26.2897 19.6929 26.438 19.6929C26.7712 19.6929 26.9058 19.8519 26.9058 20.1613V20.6297C26.9058 20.7581 26.9104 20.8181 26.9149 20.8507H26.7153V20.7044H26.7107C26.6605 20.7728 26.5704 20.878 26.3741 20.878C26.1231 20.878 25.9873 20.7717 25.9873 20.5255C25.9873 20.2413 26.284 20.1518 26.5042 20.1518C26.5886 20.1518 26.608 20.1518 26.6913 20.1571C26.6913 19.9613 26.6194 19.8613 26.4038 19.8613C26.284 19.8613 26.1528 19.9055 26.0672 19.9771L26.0546 19.7992Z"
      fill="#6F7974"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.17072 16.7113C8.81881 16.7113 9.28206 16.4808 9.55819 16.1471C9.73162 15.9366 9.81834 15.6819 9.81834 15.383C9.81834 14.8799 9.56617 14.5368 9.05728 14.3537C8.78915 14.2558 8.43201 14.1263 7.9893 14.1263H5.88869V16.7113H8.17072ZM7.81586 13.447C8.26314 13.447 8.61115 13.3923 8.85989 13.2818C9.24897 13.1091 9.53423 12.7976 9.53423 12.3471C9.53423 11.8946 9.23756 11.5346 8.82109 11.3778C8.58719 11.2894 8.24032 11.2452 7.77821 11.2452H5.88869V13.447H7.81586ZM4.86182 10.5158H8.20726C9.12007 10.5158 9.89026 10.7589 10.2748 11.2431C10.5007 11.5294 10.6148 11.8598 10.6148 12.2345C10.6148 12.6724 10.3124 13.0965 10.0352 13.3775C9.8914 13.5249 9.56279 13.6607 9.29237 13.7828C9.68944 13.9185 9.9861 14.0711 10.1835 14.2406C10.5304 14.5427 10.867 14.8931 10.867 15.4247C10.867 15.872 10.7106 16.2761 10.3946 16.6382C9.92563 17.1792 9.17827 17.4497 8.1525 17.4497H4.86182V10.5158Z"
      fill="#6F7974"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0236 12.4237V15.6128C13.0236 15.878 13.0681 16.0959 13.1571 16.2632C13.3214 16.5748 13.6272 16.7305 14.0756 16.7305C14.718 16.7305 15.6137 16.3053 15.6137 16.0159C15.6137 15.6212 15.608 15.2223 15.608 14.7139V12.4237H16.6235V17.4146H15.6616L15.6707 16.5937C15.5509 16.7916 15.3718 16.9589 15.1915 17.0947C14.8366 17.3662 14.4065 17.5031 13.8999 17.5031C13.1103 17.5031 12.5729 17.2547 12.2865 16.76C12.1324 16.4937 12.0537 16.139 12.0537 15.6949V12.4237H13.0236Z"
      fill="#6F7974"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.501 12.3571H19.4024V13.256C19.4754 13.0802 19.7527 12.8676 20.0413 12.6171C20.3289 12.3655 20.6609 12.2403 21.0363 12.2403C21.0534 12.2403 21.0842 12.2424 21.1264 12.2456C21.1687 12.2477 21.2394 12.255 21.3421 12.2645V13.1875C21.285 13.1781 21.2348 13.1718 21.1869 13.1686C21.139 13.1654 21.0877 13.1633 21.0317 13.1633C20.5537 13.1633 20.1874 13.3054 19.9307 13.5885C19.6728 13.8717 19.5359 14.199 19.5359 14.5684V17.4502H18.501V12.3571Z"
      fill="#6F7974"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.7306 16.1848C25.4876 16.6111 25.038 16.8237 24.382 16.8237C23.7863 16.8237 23.3505 16.6447 23.0755 16.2858C22.8016 15.9269 22.6636 15.4796 22.6636 14.9418C22.6636 14.3819 22.8016 13.904 23.0755 13.5072C23.3505 13.1115 23.7898 12.9126 24.3934 12.9126C25.0745 12.9126 25.5538 13.1452 25.8322 13.6114C26.0079 13.9061 26.0958 14.2903 26.0958 14.7618C26.0958 15.2849 25.9748 15.7585 25.7306 16.1848M26.3514 12.8231C25.8254 12.3884 25.183 12.1716 24.4219 12.1716C23.5719 12.1716 22.8964 12.4232 22.3921 12.9263C21.8889 13.4294 21.6367 14.1061 21.6367 14.9555C21.6367 15.748 21.8752 16.3806 22.3533 16.85C22.8314 17.3215 23.476 17.5562 24.2884 17.5562C25.2629 17.5562 25.9828 17.2899 26.4449 16.7584C26.9082 16.2269 27.1398 15.5533 27.1398 14.7387C27.1398 13.8956 26.8774 13.2568 26.3514 12.8231"
      fill="#6F7974"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.8967 10.105L24.2822 11.7754H23.5542L24.7272 10.105H25.8967Z"
      fill="#6F7974"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.25547 20.704C4.27943 20.704 4.30796 20.7019 4.34219 20.6988C4.37642 20.6946 4.41407 20.6861 4.45287 20.6725C4.49166 20.6588 4.53045 20.6388 4.56811 20.6125C4.6069 20.5862 4.64113 20.5504 4.6708 20.5062C4.70161 20.462 4.72671 20.4051 4.74611 20.3378C4.76436 20.2704 4.77463 20.1894 4.77463 20.0936C4.77463 20.001 4.7655 19.9178 4.74839 19.8442C4.73013 19.7715 4.70047 19.7094 4.65939 19.6579C4.61717 19.6063 4.56468 19.5673 4.50079 19.54C4.43461 19.5137 4.35474 19.5 4.26003 19.5H3.91317V20.704H4.25547ZM4.23841 19.3262C4.47574 19.3262 4.65945 19.3873 4.78838 19.5083C4.91618 19.6304 4.98121 19.8156 4.98121 20.0651C4.98121 20.1956 4.96638 20.3103 4.93785 20.4114C4.90819 20.5124 4.86369 20.5966 4.80322 20.6661C4.74274 20.7345 4.66515 20.7871 4.57159 20.8229C4.47689 20.8597 4.36735 20.8776 4.23841 20.8776H3.70898V19.3262H4.23841Z"
      fill="#6F7974"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.83421 20.1674C5.82965 20.0201 5.75891 19.8885 5.56494 19.8885C5.38466 19.8885 5.28767 20.0043 5.25686 20.1674H5.83421ZM5.02637 20.2972C5.02637 19.972 5.25229 19.732 5.56949 19.732C5.86843 19.732 6.04415 19.9257 6.04415 20.2319C6.04415 20.2582 6.04187 20.2856 6.03845 20.3151H5.25001C5.25685 20.5329 5.35498 20.7192 5.60942 20.7192C5.77031 20.7192 5.8787 20.6529 5.93005 20.6266L6.01106 20.7666C5.94831 20.8055 5.81709 20.8782 5.59916 20.8782C5.22034 20.8782 5.02637 20.6266 5.02637 20.2972V20.2972Z"
      fill="#6F7974"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0106 20.1678C15.0049 20.0205 14.9342 19.8889 14.7402 19.8889C14.5599 19.8889 14.463 20.0047 14.4322 20.1678H15.0106ZM14.2026 20.2972C14.2026 19.972 14.4286 19.732 14.7469 19.732C15.0458 19.732 15.2204 19.9257 15.2204 20.2319C15.2204 20.2582 15.2181 20.2856 15.2159 20.3151H14.4263C14.4331 20.5329 14.5324 20.7192 14.7857 20.7192C14.9466 20.7192 15.055 20.6529 15.1075 20.6266L15.1885 20.7666C15.1246 20.8055 14.9934 20.8782 14.7754 20.8782C14.3966 20.8782 14.2026 20.6266 14.2026 20.2972V20.2972Z"
      fill="#6F7974"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.8651 20.1678C24.8594 20.0205 24.7887 19.8889 24.5947 19.8889C24.4144 19.8889 24.3174 20.0047 24.2866 20.1678H24.8651ZM24.0571 20.2972C24.0571 19.972 24.283 19.732 24.6014 19.732C24.9003 19.732 25.0749 19.9257 25.0749 20.2319C25.0749 20.2582 25.0726 20.2856 25.0703 20.3151H24.2808C24.2876 20.5329 24.3869 20.7192 24.6402 20.7192C24.8011 20.7192 24.9095 20.6529 24.962 20.6266L25.043 20.7666C24.9791 20.8055 24.8479 20.8782 24.6299 20.8782C24.2511 20.8782 24.0571 20.6266 24.0571 20.2972V20.2972Z"
      fill="#6F7974"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.95312 19.3156H7.83284V19.4945H7.16649V19.9692H7.7872V20.146H7.16649V20.7059H7.87734V20.8638H6.95312V19.3156Z"
      fill="#6F7974"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.08947 19.7467H8.30626V19.9014C8.41351 19.7836 8.55272 19.7194 8.71132 19.7194C8.97603 19.7194 9.07987 19.8741 9.07987 20.0698V20.8634H8.85965V20.1088C8.85965 19.9741 8.8083 19.8909 8.6497 19.8909C8.51392 19.8909 8.39069 19.9835 8.30626 20.0835V20.8634H8.08947V19.7467Z"
      fill="#6F7974"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.7711 19.7467H18.9879V19.9014C19.0952 19.7836 19.2355 19.7194 19.3941 19.7194C19.6577 19.7194 19.7615 19.8741 19.7615 20.0698V20.8634H19.5413V20.1088C19.5413 19.9741 19.4899 19.8909 19.3313 19.8909C19.1967 19.8909 19.0723 19.9835 18.9879 20.0835V20.8634H18.7711V19.7467Z"
      fill="#6F7974"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.2193 19.7467H21.4372V19.9014C21.5433 19.7836 21.6837 19.7194 21.8423 19.7194C22.107 19.7194 22.2108 19.8741 22.2108 20.0698V20.8634H21.9906V20.1088C21.9906 19.9741 21.9381 19.8909 21.7795 19.8909C21.6449 19.8909 21.5205 19.9835 21.4372 20.0835V20.8634H21.2193V19.7467Z"
      fill="#6F7974"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.9439 19.7491V19.9122H9.62898V20.6089C9.62898 20.631 9.63126 20.6479 9.63469 20.6616C9.63697 20.6742 9.64381 20.6847 9.65408 20.6921C9.66435 20.6995 9.67919 20.7037 9.6963 20.7058C9.71456 20.7089 9.73852 20.71 9.76704 20.71H9.9439V20.8721H9.71113C9.66321 20.8721 9.62328 20.8689 9.5879 20.8626C9.55482 20.8563 9.52743 20.8447 9.50575 20.8268C9.48635 20.8089 9.47038 20.7847 9.46011 20.7531C9.4487 20.7205 9.44528 20.6784 9.44528 20.6268V19.9122H9.25473V19.7491H9.44528V19.3028H9.62898V19.7491H9.9439Z"
      fill="#6F7974"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1744 20.8627H10.3787V19.7334H10.1744V20.8627ZM10.1367 19.4069C10.1367 19.3321 10.1983 19.2732 10.2771 19.2732C10.3547 19.2732 10.4174 19.3321 10.4174 19.4069C10.4174 19.4795 10.3547 19.5405 10.2771 19.5405C10.1983 19.5405 10.1367 19.4795 10.1367 19.4069V19.4069Z"
      fill="#6F7974"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.2336 20.8631H18.439V19.7338H18.2336V20.8631ZM18.1934 19.4069C18.1934 19.3321 18.255 19.2732 18.3337 19.2732C18.4124 19.2732 18.474 19.3321 18.474 19.4069C18.474 19.4795 18.4124 19.5405 18.3337 19.5405C18.255 19.5405 18.1934 19.4795 18.1934 19.4069V19.4069Z"
      fill="#6F7974"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.5889 20.8627H23.7932V19.7334H23.5889V20.8627ZM23.5513 19.4069C23.5513 19.3321 23.6117 19.2732 23.6916 19.2732C23.7692 19.2732 23.8308 19.3321 23.8308 19.4069C23.8308 19.4795 23.7692 19.5405 23.6916 19.5405C23.6117 19.5405 23.5513 19.4795 23.5513 19.4069V19.4069Z"
      fill="#6F7974"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8753 20.4554C10.8902 20.5049 10.9096 20.5491 10.9392 20.5891C10.9666 20.628 11.002 20.6586 11.0465 20.6828C11.0899 20.7059 11.1412 20.7175 11.2017 20.7175C11.2633 20.7175 11.3158 20.7049 11.3591 20.6796C11.4025 20.6554 11.4379 20.6238 11.4664 20.5838C11.4926 20.5438 11.5132 20.4986 11.5246 20.4481C11.5371 20.3965 11.5428 20.346 11.5428 20.2934C11.5428 20.2376 11.5371 20.1849 11.5234 20.1334C11.5109 20.0829 11.4903 20.0376 11.463 19.9976C11.4333 19.9587 11.3979 19.9271 11.3523 19.9039C11.3078 19.8797 11.2542 19.8671 11.1903 19.8671C11.1286 19.8671 11.0762 19.8797 11.0328 19.905C10.9894 19.9292 10.9541 19.9618 10.929 20.0018C10.9016 20.0429 10.8845 20.0892 10.8719 20.1418C10.8605 20.1934 10.8548 20.247 10.8548 20.3018C10.8548 20.3544 10.8616 20.4049 10.8753 20.4554V20.4554ZM11.536 20.8543V20.7017H11.5314C11.5017 20.7638 11.4538 20.8091 11.3888 20.8364C11.3237 20.8638 11.2519 20.8775 11.1743 20.8775C11.0875 20.8775 11.0122 20.8617 10.9472 20.8301C10.8844 20.7985 10.8308 20.7554 10.7886 20.7017C10.7452 20.648 10.7133 20.5859 10.6916 20.5154C10.6699 20.4438 10.6597 20.3681 10.6597 20.2891C10.6597 20.2091 10.6688 20.1344 10.6905 20.0628C10.711 19.9923 10.743 19.9302 10.7863 19.8776C10.8285 19.8239 10.8822 19.7818 10.9449 19.7513C11.01 19.7197 11.0853 19.705 11.1697 19.705C11.1982 19.705 11.2302 19.7071 11.2633 19.7134C11.2964 19.7187 11.3283 19.7292 11.3625 19.7429C11.3956 19.7555 11.4264 19.7745 11.4572 19.7966C11.4858 19.8197 11.512 19.8471 11.5314 19.8808H11.536V19.2114H11.7197V20.8543H11.536Z"
      fill="#6F7974"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2118 20.4554C13.2255 20.5049 13.246 20.5491 13.2745 20.5891C13.3019 20.628 13.3384 20.6586 13.3818 20.6828C13.4263 20.7059 13.4776 20.7175 13.537 20.7175C13.5997 20.7175 13.6522 20.7049 13.6956 20.6796C13.7389 20.6554 13.7731 20.6238 13.8017 20.5838C13.8291 20.5438 13.8485 20.4986 13.861 20.4481C13.8736 20.3965 13.8793 20.346 13.8793 20.2934C13.8793 20.2376 13.8724 20.1849 13.8599 20.1334C13.8473 20.0829 13.8256 20.0376 13.7982 19.9976C13.7697 19.9587 13.7332 19.9271 13.6887 19.9039C13.6442 19.8797 13.5894 19.8671 13.5255 19.8671C13.4651 19.8671 13.4114 19.8797 13.3692 19.905C13.3259 19.9292 13.2905 19.9618 13.2643 20.0018C13.238 20.0429 13.2198 20.0892 13.2083 20.1418C13.1969 20.1934 13.1901 20.247 13.1901 20.3018C13.1901 20.3544 13.1981 20.4049 13.2118 20.4554V20.4554ZM13.8713 20.8543V20.7017H13.8667C13.837 20.7638 13.7891 20.8091 13.7241 20.8364C13.6602 20.8638 13.5883 20.8775 13.5107 20.8775C13.424 20.8775 13.3475 20.8617 13.2836 20.8301C13.2197 20.7985 13.1661 20.7554 13.1239 20.7017C13.0817 20.648 13.0486 20.5859 13.028 20.5154C13.0052 20.4438 12.9961 20.3681 12.9961 20.2891C12.9961 20.2091 13.0052 20.1344 13.0269 20.0628C13.0463 19.9923 13.0794 19.9302 13.1216 19.8776C13.1638 19.8239 13.2175 19.7818 13.2813 19.7513C13.3452 19.7197 13.4217 19.705 13.5061 19.705C13.5347 19.705 13.5666 19.7071 13.5985 19.7134C13.6316 19.7187 13.6647 19.7292 13.699 19.7429C13.732 19.7555 13.7629 19.7745 13.7925 19.7966C13.8222 19.8197 13.8473 19.8471 13.8667 19.8808H13.8713V19.2114H14.055V20.8543H13.8713Z"
      fill="#6F7974"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.3301 20.7737L15.4122 20.6306C15.4556 20.66 15.5606 20.719 15.6952 20.719C15.8333 20.719 15.92 20.6674 15.92 20.5748C15.92 20.479 15.8276 20.4401 15.6598 20.3695C15.4841 20.2969 15.3552 20.218 15.3552 20.0359C15.3552 19.8612 15.4978 19.7296 15.7317 19.7296C15.9223 19.7296 16.0387 19.8022 16.0763 19.8296L15.9862 19.9685C15.9451 19.9391 15.8584 19.8885 15.734 19.8885C15.6131 19.8885 15.548 19.9412 15.548 20.0275C15.548 20.1243 15.6427 20.1632 15.7728 20.2138C15.9554 20.2885 16.1185 20.3653 16.1185 20.5495C16.1185 20.7526 15.9577 20.8779 15.7009 20.8779C15.5298 20.8779 15.4008 20.8211 15.3301 20.7737"
      fill="#6F7974"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.1328 20.7737L27.215 20.6306C27.2572 20.66 27.3622 20.719 27.498 20.719C27.636 20.719 27.7227 20.6674 27.7227 20.5748C27.7227 20.479 27.6292 20.4401 27.4626 20.3695C27.2869 20.2969 27.1579 20.218 27.1579 20.0359C27.1579 19.8612 27.3006 19.7296 27.5345 19.7296C27.725 19.7296 27.8403 19.8022 27.8791 19.8296L27.7889 19.9685C27.7478 19.9391 27.66 19.8885 27.5367 19.8885C27.4147 19.8885 27.3508 19.9412 27.3508 20.0275C27.3508 20.1243 27.4455 20.1632 27.5744 20.2138C27.7581 20.2885 27.9213 20.3653 27.9213 20.5495C27.9213 20.7526 27.7604 20.8779 27.5025 20.8779C27.3325 20.8779 27.2036 20.8211 27.1328 20.7737"
      fill="#6F7974"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.1487 19.2872H17.9942V19.4671H17.3644V19.9881H17.9646V20.1628H17.3644V20.8638H17.1487V19.2872Z"
      fill="#6F7974"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.0863 19.892C23.0395 19.852 22.9779 19.831 22.9003 19.831C22.833 19.831 22.7771 19.8447 22.7326 19.8699C22.6881 19.8952 22.6527 19.9289 22.6265 19.971C22.5991 20.0131 22.5808 20.0615 22.5683 20.1162C22.5569 20.171 22.5512 20.2278 22.5512 20.2867C22.5512 20.3404 22.5569 20.393 22.5694 20.4436C22.582 20.4941 22.6002 20.5404 22.6276 20.5793C22.6527 20.6193 22.6869 20.6509 22.7292 20.6751C22.7714 20.6993 22.8204 20.7109 22.8775 20.7109C22.9688 20.7109 23.0407 20.6867 23.0909 20.6372C23.1422 20.5877 23.1742 20.5172 23.1856 20.4278H23.3738C23.3533 20.572 23.3019 20.6825 23.2187 20.7604C23.1365 20.8382 23.0224 20.8782 22.8798 20.8782C22.7953 20.8782 22.72 20.8635 22.6539 20.8351C22.5877 20.8067 22.534 20.7667 22.4895 20.713C22.445 20.6604 22.412 20.5983 22.3903 20.5256C22.3675 20.453 22.356 20.373 22.356 20.2867C22.356 20.1994 22.3675 20.1173 22.3891 20.0425C22.4108 19.9657 22.4439 19.9005 22.4884 19.8436C22.5318 19.7878 22.5877 19.7436 22.6527 19.711C22.72 19.6784 22.7965 19.6626 22.8843 19.6626C22.9482 19.6626 23.0076 19.671 23.0623 19.6868C23.1182 19.7015 23.1673 19.7268 23.2095 19.7594C23.2529 19.7921 23.2883 19.8342 23.3145 19.8847C23.343 19.9352 23.3601 19.9941 23.367 20.0636H23.1764C23.1616 19.9899 23.1319 19.9331 23.0863 19.892"
      fill="#6F7974"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.3319 19.7368H25.5156V19.9315C25.5304 19.8926 25.5681 19.8473 25.6263 19.7926C25.6856 19.7389 25.7529 19.7115 25.8305 19.7115C25.8328 19.7115 25.8396 19.7126 25.8476 19.7126C25.8568 19.7137 25.8716 19.7147 25.8921 19.7168V19.9157C25.8807 19.9136 25.8704 19.9126 25.8602 19.9126C25.8511 19.9115 25.8396 19.9115 25.8294 19.9115C25.7312 19.9115 25.6559 19.942 25.6035 20.0031C25.551 20.0641 25.5259 20.1347 25.5259 20.2146V20.8609H25.3319V19.7368Z"
      fill="#6F7974"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.36865 21.3912H28.6318V21.1386H3.36865V21.3912Z"
      fill="#6F7974"
    />
  </svg>
)

export default BuroLogo
