import React from 'react'

const LinkedinIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.4696 2.00002H3.52957C3.33915 1.99737 3.15007 2.03227 2.97314 2.1027C2.7962 2.17314 2.63487 2.27774 2.49837 2.41053C2.36186 2.54332 2.25285 2.7017 2.17756 2.87663C2.10227 3.05155 2.06218 3.2396 2.05957 3.43002V20.57C2.06218 20.7604 2.10227 20.9485 2.17756 21.1234C2.25285 21.2983 2.36186 21.4567 2.49837 21.5895C2.63487 21.7223 2.7962 21.8269 2.97314 21.8973C3.15007 21.9678 3.33915 22.0027 3.52957 22H20.4696C20.66 22.0027 20.8491 21.9678 21.026 21.8973C21.2029 21.8269 21.3643 21.7223 21.5008 21.5895C21.6373 21.4567 21.7463 21.2983 21.8216 21.1234C21.8969 20.9485 21.937 20.7604 21.9396 20.57V3.43002C21.937 3.2396 21.8969 3.05155 21.8216 2.87663C21.7463 2.7017 21.6373 2.54332 21.5008 2.41053C21.3643 2.27774 21.2029 2.17314 21.026 2.1027C20.8491 2.03227 20.66 1.99737 20.4696 2.00002V2.00002ZM8.08957 18.74H5.08957V9.74002H8.08957V18.74ZM6.58957 8.48002C6.17583 8.48002 5.77904 8.31566 5.48648 8.0231C5.19393 7.73055 5.02957 7.33376 5.02957 6.92002C5.02957 6.50628 5.19393 6.10949 5.48648 5.81693C5.77904 5.52437 6.17583 5.36002 6.58957 5.36002C6.80927 5.3351 7.03175 5.35687 7.24245 5.4239C7.45314 5.49093 7.64731 5.60171 7.81223 5.74898C7.97715 5.89625 8.1091 6.0767 8.19944 6.2785C8.28979 6.4803 8.33649 6.69891 8.33649 6.92002C8.33649 7.14112 8.28979 7.35973 8.19944 7.56154C8.1091 7.76334 7.97715 7.94378 7.81223 8.09106C7.64731 8.23833 7.45314 8.34911 7.24245 8.41613C7.03175 8.48316 6.80927 8.50493 6.58957 8.48002V8.48002ZM18.9096 18.74H15.9096V13.91C15.9096 12.7 15.4796 11.91 14.3896 11.91C14.0522 11.9125 13.7238 12.0183 13.4484 12.2132C13.1731 12.4081 12.9641 12.6827 12.8496 13C12.7713 13.2351 12.7374 13.4826 12.7496 13.73V18.73H9.74957C9.74957 18.73 9.74957 10.55 9.74957 9.73002H12.7496V11C13.0221 10.5271 13.4185 10.1375 13.896 9.87322C14.3735 9.6089 14.9141 9.47987 15.4596 9.50002C17.4596 9.50002 18.9096 10.79 18.9096 13.56V18.74Z"
      fill="#6F7974"
    />
  </svg>
)

export default LinkedinIcon
