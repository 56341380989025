import React from 'react'
import { useTranslation } from 'react-i18next'
import { AccordionHeader, AccordionBody, Typography } from '@creditas-ui/react'

import { formatQueryParams } from 'utils/formatQueryParams'
import {
  Wrapper,
  AccordionContainer,
  Item,
  MenuLink,
  ContactContainer,
  AddressContainer,
} from './Mobile.styles'

const MenuMobile = () => {
  const { t } = useTranslation()
  const loans = t('footer:navigation:loans', { returnObjects: true })
  const contact = t('footer:navigation:contact', { returnObjects: true })
  const benefits = t('footer:navigation:benefits', { returnObjects: true })
  const about = t('footer:navigation:about', { returnObjects: true })
  const companyInfo = t('footer:navigation:companyInfo', {
    returnObjects: true,
  })
  const une = t('footer:navigation:une', { returnObjects: true })
  return (
    <Wrapper>
      <AccordionContainer>
        <Item>
          <AccordionHeader>
            <Typography
              color="neutral.90"
              component="span"
              variant="bodySmBold"
            >
              {loans.label}
            </Typography>
          </AccordionHeader>
          {loans.links.map(link => (
            <AccordionBody key={link.href}>
              <MenuLink
                href={formatQueryParams(link.href)}
                rel="noreferrer"
                target="_self"
              >
                {link.text}
              </MenuLink>
            </AccordionBody>
          ))}
        </Item>
        <Item>
          <AccordionHeader>
            <Typography
              color="neutral.90"
              component="span"
              variant="bodySmBold"
            >
              {benefits.label}
            </Typography>
          </AccordionHeader>
          {benefits.links.map(link => (
            <AccordionBody key={link.href}>
              <MenuLink
                href={formatQueryParams(link.href)}
                rel="noreferrer"
                target="_self"
              >
                {link.text}
              </MenuLink>
            </AccordionBody>
          ))}
        </Item>
        <Item>
          <AccordionHeader>
            <Typography
              color="neutral.90"
              component="span"
              variant="bodySmBold"
            >
              {about.label}
            </Typography>
          </AccordionHeader>
          {about.links.map(link => (
            <AccordionBody key={link.href}>
              <MenuLink
                href={formatQueryParams(link.href)}
                rel="noreferrer"
                target="_blank"
              >
                {link.text}
              </MenuLink>
            </AccordionBody>
          ))}
        </Item>
        <Item>
          <AccordionHeader>
            <Typography
              color="neutral.90"
              component="span"
              variant="bodySmBold"
            >
              {contact.label}
            </Typography>
          </AccordionHeader>
          {contact.links.map(link => (
            <AccordionBody key={link.href}>
              <MenuLink
                href={formatQueryParams(link.href)}
                rel="noreferrer"
                target="_blank"
              >
                {link.text}
              </MenuLink>
            </AccordionBody>
          ))}
        </Item>
      </AccordionContainer>
      <ContactContainer>
        <Typography color="neutral.90" component="span" variant="bodySmBold">
          {companyInfo.label}
        </Typography>
        {companyInfo.links.map(link => (
          <AddressContainer key={link.text}>
            <Typography
              color="neutral.90"
              component="span"
              variant="bodySmRegular"
            >
              {link.text}
            </Typography>
          </AddressContainer>
        ))}
      </ContactContainer>
      <ContactContainer>
        <Typography color="neutral.90" component="span" variant="bodySmBold">
          {une.label}
        </Typography>
        {une.links.map(link => (
          <AddressContainer key={link.text}>
            <MenuLink
              href={formatQueryParams(link.href)}
              rel="noreferrer"
              target="_blank"
            >
              {link.text}
            </MenuLink>
          </AddressContainer>
        ))}
      </ContactContainer>
    </Wrapper>
  )
}

export default MenuMobile
