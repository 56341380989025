import BuroLogo from 'icons/logos/footer/Buro'
import CondusefLogo from 'icons/logos/footer/Condusef'
import CNBVLogo from 'icons/logos/footer/CNBV'
import FacebookIcon from 'icons/logos/footer/Facebook'
import LinkedinIcon from 'icons/logos/footer/Linkedin'
import TwitterIcon from 'icons/logos/footer/Twitter'
import InstagramIcon from 'icons/logos/footer/Instagram'

export const institutionLogos = [BuroLogo, CondusefLogo, CNBVLogo]
export const socialIcons = [
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  InstagramIcon,
]
