import React from 'react'

const CondusefLogo = () => (
  <svg
    width="90"
    height="32"
    viewBox="0 0 90 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Condusef">
      <g id="Group 3">
        <path
          id="Fill 1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.673 27.064L44.8828 27.0699C44.9722 27.0727 45.0616 27.0753 45.1519 27.0753H45.2538L45.3267 27.1408C48.4867 29.9759 52.6537 31.5373 57.0594 31.5373C66.3422 31.5373 73.8941 24.5674 73.8941 16.0002C73.8941 7.43294 66.3422 0.462993 57.0594 0.462993C52.6073 0.462993 48.4095 2.05263 45.239 4.93895L45.1664 5.00498L45.0646 5.00559C45.0242 5.0059 44.9839 5.00683 44.9438 5.00806L44.7562 5.01238L44.6808 4.94342C41.5098 2.05433 37.3098 0.462993 32.8549 0.462993C23.5724 0.462993 16.0207 7.43294 16.0207 16.0002C16.0207 24.5674 23.5724 31.5373 32.8549 31.5373C37.266 31.5373 41.4359 29.9729 44.5969 27.1322L44.673 27.064ZM57.0612 32C52.5575 32 48.2966 30.4159 45.0515 27.537C44.9915 27.5361 44.9317 27.5342 44.8719 27.5324C41.6261 30.4144 37.3636 32 32.8566 32C23.2977 32 15.521 24.8225 15.521 16.0001C15.521 7.17776 23.2977 0 32.8566 0C37.4085 0 41.702 1.61309 44.9583 4.54445H44.9599C48.2164 1.61309 52.5097 0 57.0612 0C66.6205 0 74.3974 7.17776 74.3974 16.0001C74.3974 24.8225 66.6205 32 57.0612 32Z"
          fill="#6F7974"
        />
      </g>
      <path
        id="Fill 4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.958 4.96398C38.1026 4.96398 32.5255 9.91479 32.5255 16.0001C32.5255 22.0852 38.1026 27.0357 44.958 27.0357C51.8138 27.0357 57.3913 22.0852 57.3913 16.0001C57.3913 9.91479 51.8138 4.96398 44.958 4.96398ZM44.9599 27.4986C37.8281 27.4986 32.0259 22.3406 32.0259 16.0002C32.0259 9.65968 37.8281 4.50122 44.9599 4.50122C52.0921 4.50122 57.8946 9.65968 57.8946 16.0002C57.8946 22.3406 52.0921 27.4986 44.9599 27.4986Z"
        fill="#6F7974"
      />
      <path
        id="Fill 6"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79.3208 20.0068V18.6086H73.5626V16.9302H77.6548V15.5314H73.5626V14.5522H79.3208V12.9441H70.0771V20.0068H79.3208Z"
        fill="#6F7974"
      />
      <g id="Group 10">
        <path
          id="Fill 8"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M84.1617 20.0068V16.9302H88.2539V15.5314H84.1617V14.5522H89.9209V12.9441H80.6763V20.0068H84.1617Z"
          fill="#6F7974"
        />
      </g>
      <path
        id="Fill 11"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.7954 17.6322H62.0184C62.0876 17.9571 62.231 18.079 62.4465 18.2493C62.797 18.5244 63.2981 18.6626 63.9487 18.6626C64.4346 18.6626 64.8087 18.5915 65.073 18.4499C65.3342 18.3092 65.4673 18.1455 65.4673 17.9594C65.4673 17.782 65.3434 17.6233 65.0924 17.4838C64.8443 17.3439 64.2638 17.2121 63.354 17.0879C61.864 16.8801 60.8019 16.6038 60.1666 16.2592C59.5262 15.9147 59.2059 15.4756 59.2059 14.9412C59.2059 14.5904 59.3697 14.2595 59.697 13.9475C60.0251 13.6359 60.5182 13.3903 61.1748 13.211C61.8326 13.0328 62.735 12.9437 63.8807 12.9437C65.2842 12.9437 66.3577 13.1063 67.0957 13.4309C67.8325 13.7555 68.2707 14.2724 68.412 14.9807H65.2152C65.129 14.6722 64.9499 14.5648 64.6766 14.4249C64.4042 14.2843 64.0259 14.2147 63.5452 14.2147C63.1489 14.2147 62.851 14.2667 62.6498 14.3702C62.4495 14.4745 62.3499 14.6012 62.3499 14.7502C62.3499 14.8585 62.4311 14.9565 62.5968 15.0433C62.7564 15.1336 63.1357 15.217 63.7364 15.2953C65.2222 15.4941 66.2856 15.6947 66.9278 15.8983C67.5704 16.102 68.0379 16.3545 68.3306 16.6564C68.6234 16.9571 68.7697 17.2947 68.7697 17.6675C68.7697 18.1055 68.5746 18.5096 68.1853 18.8791C67.795 19.2487 67.2501 19.5295 66.5499 19.7208C65.8506 19.9111 64.9662 20.0074 63.9021 20.0074C62.0316 20.0074 60.7367 19.7834 60.0171 19.3365C59.2975 18.8894 58.8899 18.3218 58.7954 17.6322Z"
        fill="#6F7974"
      />
      <path
        id="Fill 13"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.66859 17.4276H11.1011C10.8923 17.9401 10.5655 18.5311 10.1202 18.875C9.67408 19.219 9.11863 19.4779 8.45788 19.653C7.7968 19.8271 6.95418 19.9149 5.93188 19.9149C4.69095 19.9149 3.67851 19.8085 2.89189 19.5954C2.10611 19.3822 1.42714 19.0076 0.856319 18.4711C0.285161 17.935 0 17.2483 0 16.4112C0 15.2953 0.502625 14.4377 1.50637 13.8382C2.51079 13.2392 3.93074 12.9396 5.7669 12.9396C7.20474 12.9396 8.33436 13.1116 9.15708 13.4558C9.97946 13.7995 10.6519 14.6832 11.0512 15.3953L7.87352 15.4493C7.7652 15.2444 7.65087 15.0942 7.53136 14.9998C7.33362 14.8407 7.09292 14.7182 6.80726 14.6324C6.52159 14.5468 6.2025 14.5039 5.84964 14.5039C5.05183 14.5039 4.43888 14.6937 4.01298 15.073C3.69121 15.3544 3.53008 15.7972 3.53008 16.3996C3.53008 17.1465 3.72281 17.6584 4.10642 17.935C4.4912 18.2119 5.03244 18.3504 5.72879 18.3504C6.40375 18.3504 6.9607 18.2895 7.25957 18.0143C7.64218 17.6604 7.51297 17.8543 7.66859 17.4276Z"
        fill="#6F7974"
      />
      <path
        id="Fill 15"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2922 16.4703C15.2922 17.159 15.494 17.6544 15.896 17.956C16.2976 18.2567 16.8454 18.4073 17.5382 18.4073C18.2496 18.4073 18.7999 18.2599 19.1904 17.9646C19.5812 17.67 19.7769 17.1408 19.7769 16.3772C19.7769 15.7343 19.5735 15.2647 19.165 14.968C18.7588 14.6714 18.206 14.5233 17.5091 14.5233C16.8404 14.5233 16.3036 14.6743 15.8991 14.9753C15.494 15.2763 15.2922 15.7743 15.2922 16.4703ZM11.9746 16.4619C11.9746 15.3451 12.463 14.4755 13.4377 13.8534C14.415 13.2312 15.7741 12.9202 17.5159 12.9202C19.3027 12.9202 20.678 13.226 21.6457 13.8371C22.6101 14.4488 23.0945 15.3042 23.0945 16.4057C23.0945 17.205 22.8831 17.861 22.4622 18.373C22.0395 18.8845 21.428 19.2826 20.6316 19.5665C19.8322 19.8515 18.8382 19.9938 17.6479 19.9938C16.4374 19.9938 15.436 19.8715 14.6427 19.6254C13.8499 19.3798 13.2065 18.9917 12.7141 18.4591C12.2217 17.9269 11.9746 17.261 11.9746 16.4619Z"
        fill="#6F7974"
      />
      <path
        id="Fill 17"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.7559 13.0317H27.6757L31.4934 16.8893V13.0317H34.436V19.9942H31.4934L27.6976 16.1577V19.9942H24.7559V13.0317Z"
        fill="#6F7974"
      />
      <path
        id="Fill 19"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.6134 14.5899V18.4156H40.4455C41.1534 18.4156 41.658 18.3657 41.9574 18.2658C42.2572 18.1652 42.4921 17.9899 42.6617 17.7398C42.8311 17.4899 42.9165 17.0846 42.9165 16.5245C42.9165 15.7823 42.7269 15.2742 42.3472 15.0002C41.9675 14.7269 41.3389 14.5899 40.46 14.5899H39.6134ZM36.2168 12.9984H41.2598C42.253 12.9984 43.0563 13.0843 43.6679 13.2565C44.2799 13.4288 44.7865 13.6753 45.1863 13.9976C45.5857 14.3191 45.8743 14.6938 46.0554 15.121C46.2342 15.5482 46.3246 16.0008 46.3246 16.4789C46.3246 17.2282 46.1904 17.8088 45.9241 18.2216C45.6557 18.6341 45.2858 18.9803 44.8114 19.2593C44.3357 19.5377 43.8264 19.7235 43.2826 19.816C42.5381 19.9433 41.8632 20.007 41.2598 20.007H36.2168V12.9984Z"
        fill="#6F7974"
      />
      <path
        id="Fill 21"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.2395 12.9979H57.3743V17.0987C57.3743 17.5052 57.2806 17.8892 57.0926 18.2503C56.9067 18.612 56.6119 18.9279 56.2114 19.1985C55.8109 19.469 55.3902 19.6592 54.95 19.7686C54.3391 19.9216 53.6053 19.9986 52.7473 19.9986C52.2524 19.9986 51.7116 19.9751 51.1261 19.9282C50.5396 19.881 50.0517 19.788 49.6553 19.6488C49.263 19.5095 48.9032 19.3121 48.5759 19.055C48.2496 18.7992 48.026 18.5344 47.9059 18.2622C47.7109 17.8245 47.6143 17.4367 47.6143 17.0987V12.9979H50.748V17.1973C50.748 17.5725 50.9046 17.8659 51.2125 18.0772C51.5214 18.2883 51.9505 18.394 52.4973 18.394C53.0412 18.394 53.4691 18.2897 53.778 18.0817C54.086 17.8736 54.2395 17.5791 54.2395 17.1973V12.9979Z"
        fill="#6F7974"
      />
    </g>
  </svg>
)

export default CondusefLogo
