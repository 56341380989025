import { media, selectTheme, styled } from '@creditas-ui/react'

export const Wrapper = styled.section`
  width: 100vw;
  background-color: ${selectTheme('colors.neutral.10')};
`

export const MenuContainer = styled.div`
  max-width: 1366px;
  margin: 0 auto;
  padding: 48px 0 0;
  display: grid;
  grid-template-columns: 1fr 300px;

  ${media.between('5xl', '8xl')} {
    margin: 0 71px;
  }
`

export const MenuItemsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  a:last-child {
    padding-bottom: 24px;
  }
`

export const Loans = styled.div``
export const Contact = styled.div``
export const Benefits = styled.div``
export const About = styled.div``
export const CompanyInfo = styled.div``
export const Une = styled.div``
export const WImage = styled.div`
  max-height: 470px;
  position: relative;
  left: -20px;
  top: -4px;

  .img-lazy-loading {
    width: 320px;
    height: 500px;
    object-fit: cover;
  }
`
export const MenuLink = styled.a`
  padding-top: 24px;
  color: ${selectTheme('colors.neutral.60')};
  display: block;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`

export const AddressContainer = styled.div`
  display: block;
  max-width: 230px;
  padding-top: 24px;
`

export const UneLabel = styled.div`
  max-width: 52%;
`
